import React, { FC } from "react";
// libs
import Lightbox from "react-image-lightbox";
// hooks
import { useAppDispatch, useAppSelector, useModal } from "src/hooks";
// redux
import { modalSelector } from "src/redux/slices";
import { updateModal as updateModalReducer } from "src/redux/slices/modals";
// const
import { LIGHTBOX_MODAL } from "src/constants/modals";

const LightboxModal: FC = () => {
  const { isOpen, images, index } = useAppSelector(modalSelector(LIGHTBOX_MODAL));
  const { closeModal } = useModal();
  const dispatch = useAppDispatch();

  const handleCloseModal = () => closeModal(LIGHTBOX_MODAL);

  const changeIndex = (index: number) => () => {
    dispatch(updateModalReducer({ type: LIGHTBOX_MODAL, data: { index } }));
  };

  return (
    <>
      {isOpen && (
        <Lightbox
          mainSrc={images[index]}
          nextSrc={images[(index + 1) % images.length]}
          prevSrc={images[(index + images.length - 1) % images.length]}
          onCloseRequest={handleCloseModal}
          onMovePrevRequest={changeIndex((index + images.length - 1) % images.length)}
          onMoveNextRequest={changeIndex((index + 1) % images.length)}
        />
      )}
    </>
  );
};

export default LightboxModal;
